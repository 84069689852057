/*
*  Responsive attributes
*
*  References:
*  https://raw.githubusercontent.com/billjohnston/react-material-layout/master/dist/react-material-class-layout.css
*/


.flex-order-1 {
	order: 1; }

.flex-order-3 {
	order: 3; }

.layout-align-start-stretch {
	justify-content: flex-start;
	align-content: stretch;
	align-items: stretch; }

.layout-align-start,
.layout-align-start-center,
.layout-align-start-stretch {
	justify-content: flex-start; }

.layout-align-center,
.layout-align-center-start,
.layout-align-center-center,
.layout-align-center-stretch {
	justify-content: center; }

.layout-align-end,
.layout-align-end-end {
	justify-content: flex-end; }

.layout-align-space-around-center {
	justify-content: space-around; }

.layout-align-space-between,
.layout-align-space-between-center,
.layout-align-space-between-stretch {
	justify-content: space-between; }

.layout-align-center-start {
	align-items: flex-start;
	align-content: flex-start; }

.layout-align-start-center,
.layout-align-center-center,
.layout-align-space-between-center,
.layout-align-space-around-center {
	align-items: center;
	align-content: center;
	max-width: 100%; }

.layout-align-start-center > *,
.layout-align-center-center > *,
.layout-align-space-between-center > *,
.layout-align-space-around-center > * {
	max-width: 100%;
	box-sizing: border-box; }

.layout-align-end-end {
	align-items: flex-end;
	align-content: flex-end; }

.layout-align-start-stretch,
.layout-align-center-stretch,
.layout-align-space-between-stretch {
	align-items: stretch;
	align-content: stretch; }

.flex {
	flex: 1;
	box-sizing: border-box; }

.flex-grow {
	flex: 1 1 100%;
	box-sizing: border-box; }

.layout-column > .flex-40 {
	flex: 1 1 100%;
	max-width: 100%;
	max-height: 40%;
	box-sizing: border-box; }

.layout-row > .flex-90 {
	flex: 1 1 100%;
	max-width: 90%;
	max-height: 100%;
	box-sizing: border-box; }

.flex-100 {
	flex: 1 1 100%;
	max-width: 100%;
	max-height: 100%;
	box-sizing: border-box; }

.layout-row > .flex {
	min-width: 0; }

.layout-column > .flex {
	min-height: 0; }

.layout-column, .layout-row {
	box-sizing: border-box;
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex; }

.layout-column {
	flex-direction: column; }

.layout-row {
	flex-direction: row; }

.layout-wrap {
	flex-wrap: wrap; }

@media (min-width: 600px) and (max-width: 959px) {
	.layout-row > .flex-sm-70 {
		flex: 1 1 100%;
		max-width: 70%;
		max-height: 100%;
		box-sizing: border-box; }
}
@media (min-width: 960px) {
	.layout-row > .flex-gt-sm-45 {
		flex: 1 1 100%;
		max-width: 45%;
		max-height: 100%;
		box-sizing: border-box; }
	.layout-row > .flex-gt-sm-55 {
		flex: 1 1 100%;
		max-width: 55%;
		max-height: 100%;
		box-sizing: border-box; }
	.flex-gt-sm-100 {
		flex: 1 1 100%;
		max-width: 100%;
		max-height: 100%;
		box-sizing: border-box; }
}
