@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
	--primary: #7A3AED;
	--secondary: #5D12E4;
	--teal: #00FFED;
	--teal-shadow: #00e1d2;
	--placeholder: #6d7a87;
	--gray-1: #666666;
	--gray-2: #CCC;
	--gray-3: #444444;
	--gray-4: rgba(255, 255, 255, 0.7);
	--tertiary: #6D7A87;
	--dark-1: #274364;
	--dark-2: #132D4A;
	--blue: #08A0E9;
	--dark-3: #04172D;
	--dark-4: #1e1e1e;
	--dark-rgba3: rgba(4, 23, 45, 0.4);
	--dark-5: #2b4767;
	--dark-google: #131314;
	--dark-google-border: #8E918F;
	--light-3: #9CB5D1;
	--light-4: #7295BC;
	--light-5: #5276A0;
	--purple-1: #C3B4DC;
	--purple-2: #7A3BED;
	--purple-default: #AB00F2;
	--pink: #FF007A;
	--yellow: #FFEA00;
	--link: #5FC49A;
	--link-purple: #BE28A9;
	--youtube-red: #FF0000;
	--youtube-white: #FFFFFF;
	--youtube-black: #282828;
	--twitch-purple: #9146FF;
	--twitch-white: #FFFFFF;
	--twitch-black: #000000;
	--tiktok-white: #FFFFFF;
	--tiktok-black: #000000;
	--orange-1: #FF7F11;
	--warning: #ef913f;
}

@layer components {
	.bg-primary-gradient {
		background: linear-gradient(261deg, #7840b6, #5840b6);
	}
	.bg-secondary-gradient {
		background: linear-gradient(253deg, #401d66, #3e2c84)
	}
}

@layer utilities {
	/* Hide scrollbar for Chrome, Safari and Opera */
	.no-scrollbar::-webkit-scrollbar {
		display: none;
	}
	/* Hide scrollbar for IE, Edge and Firefox */
	.no-scrollbar {
		-ms-overflow-style: none;  /* IE and Edge */
		scrollbar-width: none;  /* Firefox */
	}
}

/* TODO *design* remove this on new style implementation */
*, *::placeholder {
	color: white;
}

input::placeholder {
	color: gray;
}

html, body {
	height: 100%;
	position: relative;
}

body {
	margin: 0;
	padding: 0 !important;
	font-family: Roboto;
	white-space: pre-line;
	overflow-y: scroll !important;
	overflow-x: hidden;
}

.background{
	background-color: #04172D;
}

.mui-fixed {
	padding: 0 !important;
}

.hide {
	display: none;
}

/* radix-ui helper class */
/* https://www.radix-ui.com/primitives/docs/components/tooltip#constrain-the-content-size */
.TooltipContent {
	width: var(--radix-tooltip-trigger-width);
	max-height: var(--radix-tooltip-content-available-height);
}


.drift-frame-controller, .live-chat-loader-placeholder {
	z-index: 1000 !important;
}

.dark-scrollbar::-webkit-scrollbar {
	height: 0.5rem;
	width: 0.5rem;
	background: var(--dark-2);
}

.dark-scrollbar::-webkit-scrollbar-thumb {
	border-radius: 0.25rem;
	background: var(--secondary);
}

.dark-scrollbar::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner {
	background-color: var(--dark-3),
}

/* styles.css */
.AccordionContent {
	overflow: hidden;
}
.AccordionContent[data-state="open"] {
	animation: slideDown 300ms ease-out;
}
.AccordionContent[data-state="closed"] {
	animation: slideUp 300ms ease-out;
}

@keyframes slideDown {
	from {
		height: 0;
	}
	to {
		height: var(--radix-accordion-content-height);
	}
}

@keyframes slideUp {
	from {
		height: var(--radix-accordion-content-height);
	}
	to {
		height: 0;
	}
}
